export default {
  namespaced: true,
  state: {
    standards: [
      {
        id: 'standard-01',
        name: '一、基本要求',
        categories: [
          {
            id: 'standard-01-01',
            name: '1、客户服务场所',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 设置客户服务中心，配置办公用具、电话、传真机、复印机、电脑、打印机、网络等办公设备。',
              '2. 公示物业服务企业资质证书或复印件、项目负责人及主要服务人员照片，物业服务事项、服务标准、收费项目、收费标准等相关信息。提供特约服务的，公示特约服务项目及服务标准、收费标准。',
              '3. 客户服务中心工作时间，工作日及节假日不少于8小时，其他时间设置值班人员。',
              '4. 设置并公示24小时服务电话。'
            ]
          },
          {
            id: 'standard-01-02',
            name: '2、人员',
            description: '共20分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 从业人员按照相关规定取得职业资格证书。',
              '2. 服务人员分岗位统一着装，佩戴标志。',
              '3. 配备物业服务项目负责人。',
              '4. 项目负责人具有3年以上物业服务企业同等岗位工作经历，为本市项目负责人信用信息档案登记在册人员。'
            ]
          },
          {
            id: 'standard-01-03',
            name: '3、制度',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 建立共用部位及共用设施设备维修养护、消防安全防范、绿化养护、环境卫生、公共秩序维护等管理制度。',
              '2. 建立突发公共事件的应急预案。',
              '3. 建立物业服务工作记录。',
              '4. 建立培训体系，定期组织培训、考评。'
            ]
          },
          {
            id: 'standard-01-04',
            name: '4、档案',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 建立物业管理档案。',
              '2. 配备专职档案管理人员，有档案资料室。',
              '3. 应用物业服务软件管理基本信息、基础资料、维修养护资料、收费资料等。'
            ]
          },
          {
            id: 'standard-01-05',
            name: '5、标志',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: ['1. 设置安全警示、作业施工警示、温馨提示等物业服务标志。']
          },
          {
            id: 'standard-01-06',
            name: '6、客户服务',
            description: '共30分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 每年第一季度公示上一年度物业服务合同履行情况、收支情况、本年度收支预算。',
              '2. 涉及业主或使用人正常生活的重要物业服务事项，应在主要出入口、各楼单元门内张贴 通知，履行告知义务。',
              '3. 对违反治安、规划、环保等方面法律、法规规定的行为，应劝阻并报告相关行政主管部门。',
              '4. 水、电急修 20 分钟内、其他报修 30 分钟内到达现场；由专项服务企业负责的设施设 备应在 30 分钟内告知。报修回访率应不少于 60%。',
              '5. 业主或使用人提出的意见、建议、投诉在 2 个工作日内回复。投诉回访率 100%。',
              '6. 每年至少公开征集 1 次物业服务意见，问卷率 80%以上，公示整改情况。',
              '7. 每月组织 1 次项目服务质量检查，重要节假日前组织安全检查。',
              '8. 每年组织业主参观共用设施设备机房。',
              '9. 每年至少组织 4-6次社区文化活动。',
              '10. 重要节日进行美化装饰。',
              '11. 设置公共信息栏；配合相关部门进行公益性宣传。',
              '12. 按规定投保物业共用部位、共用设施设备及公众责任保险。',
              '13. 有条件的提供室内清洁、洗衣、做饭、洗车、财物保管、票务代理等特约服务。'
            ]
          },
          {
            id: 'standard-01-07',
            name: '7、专项服务委托管理',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 签订专项服务委托合同，明确各方权利义务。',
              '2. 专项服务企业应符合相关行政主管部门的资质要求；操作人员应持有相应的职业资格证书。',
              '3. 专项服务企业人员统一着装，佩戴标志。',
              '4. 对专项服务进行监督及评价。'
            ]
          }
        ]
      },
      {
        id: 'standard-02',
        name: '二、共用部位及共用设施设备运行、维修养护',
        categories: [
          {
            id: 'standard-02-01',
            name: '1、综合管理',
            description: '共15分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 建立房屋及共用设施设备的基础档案。',
              '2. 运行、检查、维修养护记录应每月归档。',
              '3. 组织实施房屋使用安全情况评估检查。',
              '4. 共用部位检查中发现的问题，应按照责任范围编制修缮计划，并按计划组织修缮；共用 设施设备运行中出现的故障及检查中发现的问题，应即时组织修复。',
              '5. 每年第四季度制定下一年度维修养护计划。',
              '6. 特种设备按照有关规定运行、维修养护和定期检测。',
              '7. 雷电、强降水、大风、沙尘暴等极端天气前后进行检查并落实防范措施。',
              '8. 设备机房\n1）每月清洁 1 次，室内无杂物。\n2）设置挡鼠板、鼠药盒或粘鼠板。\n3）在明显易取位置配备消防器材，每月检查 1 次消防器材，确保完好有效。\n4）设施设备标志、标牌齐全。\n5）在显著位置张贴或悬挂相关制度、证书。\n6）交接班记录、工作日志等齐全、完整。'
            ]
          },
          {
            id: 'standard-02-02',
            name: '2、共用部位',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 房屋结构 每季度检查 1 次梁、板、柱等结构构件，外观出现变形、开裂等现象时，应申请房屋安全鉴定，同时采取必要的防护措施。按鉴定结果组织修缮。',
              '2. 建筑部件：1）每季度检查 1 次外墙贴饰面或抹灰、屋檐、阳台、雨罩、空调室外机支撑构件等。2）每 2 周巡查 1 次共用部位的门、窗、玻璃等。3）每 2 月检查 1 次共用部位的室内地面、墙面、天棚；室外屋面、散水等。4）每年上汛前和强降雨后检查屋面防水和雨落管等。',
              '3. 附属构筑物：1）每 2 周巡查 1 次道路、场地、阶梯及扶手、侧石、管井、沟渠等。2）每 2 月检查 1 次雨、污水管井等。3）每 2 周巡查 1 次大门、围墙、围栏等。4）每 2 周巡查 1 次休闲椅、凉亭、雕塑、景观小品等。5）每年检测 1 次防雷装置。'
            ]
          },
          {
            id: 'standard-02-03',
            name: '3、空调系统',
            description: '共5分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 运行前对冷水机组、循环水泵、冷却塔、风机、风机盘管、水处理等设施设备进行系统 性检查。',
              '2. 运行期间每日巡视 1 次空调系统，保证室内温度符合相关规定。',
              '3. 制定节能措施，每月对能源消耗进行统计、分析。',
              '4. 每年检查 1 次管道、阀门并除锈。',
              '5. 每年检验 1 次压力容器、仪表及冷却塔噪声。',
              '6. 每年清洗消毒 1 次新风机、空气处理机滤网、表冷器、箱体等；每年清洗消毒 2 次风 机盘管滤网；每两年清洗消毒 1 次风管。',
              '7. 每年对空调系统进行 1 次整体性维修养护。'
            ]
          },
          {
            id: 'standard-02-04',
            name: '4、二次供水系统',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 按照卫生防疫部门的要求使用消毒设备，水箱按规定清洗消毒、水质化验，水质符合国家生活饮用水卫生标准。',
              '2. 每日巡视 2 次水箱间、水泵房，检查设备运行状况。',
              '3. 每 2 月切换 1 次备用水泵。',
              '4. 每季度检查 1 次水泵润滑情况，补充或更换润滑剂；每年养护 1 次水泵。',
              '5. 每年对供水管道、阀门等进行除锈、刷漆，每年入冬前对暴露管道进行防冻处理。',
              '6. 水箱、蓄水池盖板应保持完好并加锁，钥匙由专人保管；溢流管口、透气口应安装金属 防护网并保持完好。'
            ]
          },
          {
            id: 'standard-02-05',
            name: '5、排水系统',
            description: '共15分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 排水设施每年上汛前对雨、污水井、屋面雨水口等设施进行检查，组织清理、疏通。 每次降雨前后对主要排水口、管井进行检查。',
              '2. 污水泵 汛期每日巡视 2 次，平时每周巡视 1 次，检查设备运行状态；每周进行 1 次手动启动测试；每季度养护 1 次。',
              '3. 化粪池 每半年检查 1 次化粪池；组织安排清掏。'
            ]
          },
          {
            id: 'standard-02-06',
            name: '6、照明和电气设备',
            description: '共15分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 楼内照明 每日巡视 1 次，一般故障 12 小时内修复；其他复杂故障 3 日内修复。',
              '2. 楼外照明 每周巡视 3 次；一般故障 1 日内修复；其他复杂故障 5 日内修复；每 2 周调整 1 次时间控制器。',
              '3. 应急照明 每日巡视 1次，发现故障，即时修复。',
              '4. 低压柜 每日巡视 2 次设备运行状况；每半年养护 1 次，养护内容包括紧固、检测、清扫；每年检1 次电气安全；每半年检测 1 次接地电阻；每年校验 1 次仪表。',
              '5. 低压配电箱和低压线路：每月巡视 2 次设备运行状况；每年养护 1 次，养护内容包括紧固、检测、清扫；每半年切换1次双路互投开关。',
              '6. 控制柜：每周巡视 1 次设备运行状况；每年养护 2 次，养护内容包括紧固、检测、调试、清扫；每 半年校正 1 次各种电器元件或控制设备的设定值；每年检查 1 次远控装置。',
              '7. 发电机 每月试运行 1 次，保证运行正常；每年 1 次带负荷运行；每年活化 2 次蓄电池；每日巡视 1 次充电机和蓄电池。',
              '8. 检测 每 3 年检验 1 次内部核算电能表。',
              '9. 配电室、楼层配电间 防小动物措施完备；穿墙线槽周边封堵严密；锁具完好；电缆进出线和开关标志清晰、准确。'
            ]
          },
          {
            id: 'standard-02-07',
            name: '7、安全防范系统',
            description: '共15分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 报警控制管理主机 每日巡查 1 次，检查设备运行状态；每周表面清洁 1 次；每年内部除尘 1 次。',
              '2. 对讲门口机 每 2 周检查 1 次按键、显示屏等； 2 周表面清洁 1 次；每半年内部除尘 1 次。',
              '3. 网络控制箱 每季度检查 1 次外观、接线；每季度表面清洁 1 次；每半年内部除尘 1 次。',
              '4. 红外对射探测器 每 2 月检查 1 次接线、探测范围、防水状况等；每 2 月表面清洁 1 次；每半年内部除尘1 次。',
              '5. 图像采集设备 每周检查 2 次监视画面、录像功能；每 2 周表面清洁 1 次；每半年内部除尘 1 次。',
              '6. 摄像机 每季度检查 1 次聚焦、红外夜视、接线、防水状况等，进行相应的调试；每季度镜头表面清洁 1 次；每年防护罩内部除尘 1 次。',
              '7. 解码器 每季度检查 1 次防雨罩、接线等，进行相应的调试；每季度表面清洁 1 次；每年内部除尘1次。',
              '8. 云台：每季度检查 1 次运转、接线，进行清晰度调校；每季度表面清洁 1 次；每年转动部位上润 滑油 1 次。',
              '9. 巡更点 每 2 周检查 1 次外观；每 2 周表面清洁 1 次；每日存储巡更记录。',
              '10. 读卡器 每 2 周检查 1 次外观；每 2 周表面清洁 1 次，查看防水状况。',
              '11. 电磁锁门锁 每月检查 1 次吸力、外观、接线。',
              '12. 出门按钮 每月检查 1 次开锁功能、接线。'
            ]
          },
          {
            id: 'standard-02-08',
            name: '8、电梯',
            description: '共5分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 电梯在投入使用前或者投入使用后 30 日内，应向相关行政主管部门办理使用登记。登 记标志应置于或附着于电梯的显著位置。',
              '2. 在电梯安全检验合格有效期届满前 1 个月，应向电梯检验检测机构提出定期检验申请。',
              '3. 日常维护保养和定期检查中发现的问题应及时排除；电梯的安全附件、安全保护装置、 测量调控装置及有关附属仪器仪表应每年进行校验、检修。',
              '4. 应与有资质的电梯制造、安装、改造、维修单位签订电梯维保合同，约定维护保养的内 容、要求、频次、期限以及双方的权利、义务与责任。对专项服务企业的维修保养工作进行监督，对专项服务企业定期检修计划的落实情况进行监督。',
              '5. 配备电梯安全管理人员。定期对电梯安全管理人员进行特种设备安全培训，保证其具备 必要的安全知识。',
              '6. 制定电梯应急救援预案并定期演练。发生电梯困人或其它重大事件时，应立即启动事故 应急预案，组织救援，并及时向相关行政主管部门报告。物业服务人员应在 15 分钟内到达现场， 专业维修人员应在 30 分钟内到达现场。'
            ]
          },
          {
            id: 'standard-02-09',
            name: '9、水景',
            description: '共10分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 启用前进行防渗漏和防漏电检查，防止渗漏，保证用电安全。',
              '2. 使用期间每日巡查 1 次喷水池、水泵及其附属设施，每 2 周检查 1 次防漏电设施。',
              '3. 设置必要的安全警示标志。',
              '4. 水质符合卫生要求。'
            ]
          }
        ]
      },
      {
        id: 'standard-03',
        name: '三、消防安全防范及公共秩序维护',
        categories: [
          {
            id: 'standard-03-01',
            name: '1、综合管理',
            description: '共20分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 建立、落实消防安全责任制，设消防安全负责人，逐级逐岗明确消防安全职责。',
              '2. 成立义务消防队，配备必要的消防器材，相关人员掌握消防基本知识和技能；每年组织1次有员工、业主或使用人参加的消防演练。',
              '3. 设置消防安全宣传专栏，组织开展经常性的消防安全宣传教育；每年对员工进行 2 次消防安全培训。',
              '4. 每日防火巡查 1 次，每月防火检查 1 次，按照规定每年检测 1 次建筑消防设施；保障疏散通道、安全出口、消防车通道和消防设施、器材符合消防安全要求。',
              '5. 发现消防安全违法行为和火灾隐患，立即纠正、排除；无法立即纠正、排除的，应向公 安机关消防机构报告。',
              '6. 消防控制室设专人 24 小时值班，每班 2 人，及时处理各类报警、故障信息。',
              '7. 发生火情立即报警，组织扑救初起火灾，疏散遇险人员，协助配合公安机关消防机构工作。'
            ]
          },
          {
            id: 'standard-03-02',
            name: '2、消防设备养护',
            description: '共20分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 火灾自动报警系统：1） 火灾报警控制器、联动控制设备 每日巡查 1 次设备运行情况，保证 24 小时连续正常运行；每月检查测试 1 次报警控制器、联动控制设备的报警、联动控制、显示、打印等功能；每年机柜内部除尘 1 次。2） 火灾报警探测器、手动报警按钮、警报装置 每月抽查测试 1 次火灾报警探测器、手动报警按钮、警报装置的报警、警报功能；探测器投入运行 2 年后，每 3 年由专业清洗单位清洗 1 次。3） 备用电源 每月检测切换 1 次主、备电源；每季度备用电源、蓄电池充放电试验和表面除尘 1 次。',
              '2. 消防广播系统：每月检查测试 1 次消防专用电话、重要场所的对讲电话、对讲电话主机、播音设备、扩音器、扬声器的联动、强制切换功能，并测试音量；每年机柜内部除尘 1 次；每年机柜内的设备内部除尘 1 次。',
              '3. 防排烟系统：每月检查测试 1 次防排烟风机、排烟阀的联动功能，核对风速；每年养护 1 次防排烟风机、电源控制柜、风口、排烟阀等。',
              '4. 防火分隔设施：每月抽查测试 1 次防火门的启闭功能、防火卷帘的手动和自动控制功能、电动防火阀的联 动关闭功能；每年在防火卷帘门的电机转动、齿轮链条传动部位补充 1 次润滑油，电控箱内部 除尘 1 次；每年维修养护 2 次防火门附件，在门的转动部位补充 1 次润滑油。',
              '5. 水灭火系统：消防泵、喷淋泵每月盘车 1 次，每季度检查 1 次润滑情况；每年养护 1 次室内、外消火栓。',
              '6. 应急照明、疏散指示标志每月测试切断正常供电 1 次，测量 1 次照度和供电时间。',
              '7. 消防电梯每月检查测试 1 次按钮迫降和联动控制功能，轿箱内消防电话。',
              '8. 灭火器 每日巡查 1 次灭火器数量、位置情况，每月检查核对 1 次灭火器选型、压力和有效期，保证处于完好状态。'
            ]
          },
          {
            id: 'standard-03-03',
            name: '3、公共秩序维护',
            description: '共60分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 出入口有专人 24 小时值守。',
              '2. 制定巡查路线，巡视检查并做好记录。6：00 至 22：00 巡视 3 次、其中楼内巡视 1 次；22：00 至次日 6：00 巡视 2 次，夜间巡查 2 人同行。',
              '3. 巡视检查停车场，维护道路、场地使用秩序。',
              '4. 机动车辆登记出入。',
              '5. 安防控制室设专人 24 小时值守；监控影像资料、报警记录应留存 30 日备查；管理制度、应急预案张贴在显著位置。',
              '6. 对违法行为立即报警，协助相关部门处理。',
              '7. 每年进行 3 次专项应急预案演习，做好记录。',
              '8. 备勤人员 24 小时待岗，人数不少于秩序维护专业人员的 20%，配备必要的应急工具。',
              '9. 记录与档案：1）各项工作记录完整有效。2）档案齐全。'
            ]
          }
        ]
      },
      {
        id: 'standard-04',
        name: '四、绿化养护',
        categories: [
          {
            id: 'standard-04-01',
            name: '1、绿化基本要求',
            description: '共30分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 乔木 植株生长良好，树冠基本完整，植株保存率高于 98%。年成活率高于 98%，生长季节叶片保存率高于90%。',
              '2. 灌木植株生长良好，主枝完整，保存率高于 98%，年成活率高于 98%。',
              '3. 绿篱和色块植株生长良好，轮廓清晰，高度一致，修剪和补种及时，保存率高于 98%，年成活率高于 98%。 1.4 地被和花坛植物 地被植物覆盖率高于 95%，花坛植物死亡率超过 2%后能及时补栽。',
              '4. 草坪草坪整齐，覆盖率高于 95%。'
            ]
          },
          {
            id: 'standard-04-02',
            name: '2、绿化养护内容',
            description: '共50分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 灌溉：有计划地进行浇灌，灌溉水下渗充足均匀，采用节水灌溉设备和措施，及时排涝；一般植物确保在萌芽前、4 月、5 月、秋季、入冬前浇水 1 次，需水量大的植物根据长势和土壤情况合理 灌溉。',
              '2. 施肥：乔木每年施肥 1 次；灌木每年施肥 2 次至 3 次；地被和草坪植物每年施肥 3 次至 4 次;花坛植物根据生长情况进行追肥；有条件的，应使用有机肥料。',
              '3. 病虫害防治：根据病虫害发生规律，植物生长季每月检查 4 次病虫害情况；并根据检查结果针对不同植物采取相应的防治措施，严禁高毒农药，无明显病虫害发生。',
              '4. 整形修剪：乔木每年修剪 1 次至 2 次，部分植物进行生长季修剪；灌木根据需要及时修剪开败的残花果，全年至少修剪 2 次；绿篱和色块生长整齐，每年五一前、七八月份、国庆节前和冬季修剪 1次；冷季型草坪生长季节平均每月修剪 2 次，全年至少修剪 15 次。',
              '5. 除草：每年全面除草 4 次，重点绿地增加除草次数；出现杂草的绿地面积不超过总绿地面积的 5%，发现 50 厘米以上的杂草应及时清除。',
              '6. 垃圾处理：绿化作业产生的垃圾和绿地内的垃圾杂物每周处理 5 次。'
            ]
          },
          {
            id: 'standard-04-03',
            name: '3、工作检查',
            description: '共20分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 生长季节每月检查 4 次绿化工作。',
              '2. 编制每月绿化养护措施和工作计划。',
              '3. 绿化档案齐全。'
            ]
          }
        ]
      },
      {
        id: 'standard-05',
        name: '五、保洁服务',
        categories: [
          {
            id: 'standard-05-01',
            name: '1、生活垃圾收集清运',
            description: '共30分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 实行生活垃圾分类收集。',
              '2. 配置密闭式垃圾收集容器，有分类标志。塔楼按栋、板楼按单元设置垃圾收集容器。',
              '3. 每 2 周至少清洗 1 次垃圾收集容器。蝇、蚊孳生季节每日喷洒 1 次杀虫药。',
              '4. 每日清运 1 次生活垃圾到指定的垃圾消纳场所，不得乱堆乱倒。',
              '5. 垃圾清运车密闭运输、外观整洁。'
            ]
          },
          {
            id: 'standard-05-02',
            name: '2、物业共用部分清洁',
            description: '共50分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 楼内：1） 大堂、一层候梯厅 每日清扫并清拖 1 次大堂、一层候梯厅地面。 每周擦拭 2 次信报箱。每周擦拭 1 次大堂玻璃。每 2 周擦拭 1 次大堂、候梯厅墙面。 每日巡视保洁 2 次大堂、一层候梯厅。2） 电梯间及左右两侧每日清扫 1 次、清拖 1 次楼道，楼梯每周清拖1次，楼梯地面每周清扫2次。每周擦拭 2 次楼梯扶手、栏杆、窗台、防火门、消火栓、 指示牌等共用设施。每季度除尘 1 次楼内灯具。 根据地面材质，定期做地面清洗、养护。 每日巡视保洁 2 次楼道、楼梯。',
              '2. 电梯轿箱 每日擦拭 1 次电梯轿厢门、面板。每日清拖 1 次轿厢地面。不锈钢或其他材料装饰的电梯轿厢，每月护理 2 次；石材装饰的电梯轿厢，每季度养护 1次。每日巡视保洁 3 次电梯轿箱。',
              '3. 天台、屋面每季度清扫 1 次天台、屋面；雨季期间，每月清扫 1 次天台、屋面。 每 2 周巡查 1 次天台、内天井，有杂物及时清扫。',
              '4. 楼外道路及设施 每日扫 1 次、巡视保洁 3 次楼外道路每2周清洁 1 次楼外公共照明及共用设施；每 2 月清洁 1 次雨蓬、门头等。',
              '5. 水景 根据水质情况进行消毒净化处理。使用期间每日清洁 1 次水面；每年清洁 2 次水池池底。',
              '6. 有害生物预防和控制 配合相关部门进行有害生物预防和控制。投放药物应预先告知，投药位置有明显标志。',
              '7. 雨雪天气清洁：雨后对小区内主路、干路积水进行清扫。 降雪时，及时清扫积雪，铲除结冰。夜间降雪的，主要道路的冰雪在次日 10：00 前清扫干净。'
            ]
          },
          {
            id: 'standard-05-03',
            name: '3、工作检查记录',
            description: '共20分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 每日检查 1 次清洁质量，做好记录。',
              '2. 每月全面检查 1 次清洁质量，做好记录。',
              '3. 清洁档案齐全。'
            ]
          }
        ]
      },
      {
        id: 'standard-06',
        name: '六、装饰装修管理',
        categories: [
          {
            id: 'standard-06-01',
            name: '1、装修管理',
            description: '共100分，每发现一项不符合扣除1分，扣完为止',
            items: [
              '1. 建立装饰装修管理服务制度。',
              '2. 建立装饰装修管理档案。',
              '3. 受理业主或使用人的装饰装修申报登记，与业主或使用人、装饰装修企业签订住宅室内装饰装修管理服务协议，告知业主或使用人在装饰装修工程中的禁止行为和注意事项。',
              '4. 装饰装修期间每日巡查 1 次现场，发现业主或使用人未申报登记或者有违法违规行为 的，应立即劝阻；拒不改正的，报告相关行政主管部门及业主、业主委员会，并在物业管理区域 内公示。',
              '5. 装饰装修结束后进行检查，对违反住宅室内装饰装修管理服务协议的当事人按照约定处理。委托清运装修垃圾的，应在指定地点临时堆放，采取围挡、遮盖措施，在当日内清运。 自行清运装修垃圾的，应采用袋装运输或密闭运输的方式，在当日内清运'
            ]
          }
        ]
      }
    ]
  }
}