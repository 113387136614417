export default {
  namespaced: true,
  state: {
    tcb: null,
    db: null,
    _: null
  },
  mutations: {
    setTcb(state, tcb) {
      state.tcb = tcb
      state.db = tcb.database()
      state._ = state.db.command
    },
    reset(state) {
      state.tcb = null
      state.db = null
      state.command = null
    }
  },
  actions: {
    reset({ commit }) {
      commit('reset')
    }
  }
}
