import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://81cf97e9bda54e75957bea00be64c425@o1135029.ingest.sentry.io/6183538',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        'cms.yangguanglijing.com',
        'prod-3gii3men1a5ab62b-1308172280.tcloudbaseapp.com',
        /^\//
      ]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

import * as filters from './filters'

// 安装VeeValidate验证
import Validate from './scripts/vee-validate'
Vue.use(Validate)


// 安装全局插件
import plugins from '@/plugins'
Vue.use(plugins)

import Vant from 'vant'
// import 'vant/lib/index.css'
import 'vant/lib/index.less'
Vue.use(Vant)

import { Lazyload } from 'vant'

Vue.use(Lazyload)

// 启用过滤器
Object.keys(filters).map(key => {
  Vue.filter(key, filters[key])
})

// 引入系统级别的样式
import '@/styles/index.scss'
import 'leaflet-easybutton/src/easy-button.css'
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp']

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
