import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

let files = require.context('./modules', true, /\.js$/)

const routes = files.keys().reduce((acc, cur) => {
  acc = acc.concat(files(cur).default || files(cur))
  return acc
}, [])

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  let appName = process.env.VUE_APP_NAME
  // 修改如果打开的菜单有标题，则修改标题
  if (!to.meta.name) {
    document.title = appName
  } else {
    if(typeof to.meta.name == 'string') {
      document.title = to.meta.name
    } else {
      document.title = to.meta.name(to)
    }
  }
  if (to.matched.some(r => r.meta.authRequired)) {
    // eslint-disable-next-line no-console
    console.warn(
      '[router] router index, 判断需要登录',
      store.state.Common?.Credential?.loginState
    )

    if (store.state.Common?.Credential?.loginState) {
      // eslint-disable-next-line no-console
      console.log('[router] 当前用户已经登录', to)

      next()
    } else {
      // eslint-disable-next-line no-console
      let redirect = JSON.stringify({
        name: to.name,
        params: to.params,
        query: to.query
      })
      // eslint-disable-next-line no-console
      console.log('[router] 当前用户未登录，需要登录', redirect)
      sessionStorage.setItem('redirect', redirect)
      next({
        name: 'wechat.oauth.login'
      })
    }
  } else {
    next()
  }
})

export default router
