import xss from 'xss'

export default function (html) {
  let options = {
    whiteList: {
      h1: ['class', 'style'],
      h2: ['class', 'style'],
      h3: ['class', 'style'],
      h4: ['class', 'style'],
      a: ['class', 'href', 'onclick'],
      ul: ['class'],
      li: ['class', 'style', 'id'],
      p: ['class', 'style', 'align'],
      div: ['class', 'style'],
      span: ['class', 'style'],
      section: ['class', 'style'],
      strong: ['class', 'style'],
      table: [
        'border',
        'style',
        'class',
        'cellspacing',
        'cellpadding',
        'rules'
      ],
      tbody: ['class', 'style'],
      tr: ['class', 'style'],
      td: ['class', 'style', 'width', 'rowspan', 'colspan'],
      br: [],
      i: ['class'],
      img: ['src', 'style', 'class'],
      video: ['src', 'style', 'class', 'controls'],
      em: ['class'],
      u: ['class'],
      sub: ['class'],
      sup: ['class'],
      s: ['class'],
      ol: ['class', 'style'],
      hr: []
    }
  }

  return xss(html, options)
}
