const files = require.context('./', false, /\.js$/)
const modules = files.keys().reduce((acc, cur) => {
  const name = cur.replace(/\.\/(\w+)\.js$/g, '$1')
  if(name === 'index') {
    return acc
  }
  acc[name] = files(cur).default
  return acc
}, {})

export default {
  namespaced: true,
  modules
}
