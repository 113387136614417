import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
let files = require.context('./modules', true, /\w+\/index\.js$/)

const modules = files.keys().reduce((acc, cur) => {
  const name = cur.replace(/\.\/(\w+)\/index\.js$/g, '$1')

  acc[name] = files(cur).default
  return acc
}, {})
Vue.use(Vuex)

// 记录需要持久化的state
const dataState = createPersistedState({
  storage: window.localStorage,
  paths: []//'Common.Credential.loginState']
})
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [dataState],
})
