export default [
  {
    path: '/',
    redirect: '/wechat/oauth'
  },
  {
    path: '/wechat/oauth',
    name: 'wechat.oauth.login',
    component: () => import('@/views/wechat/oauth/index.vue')
  }
]
