import store from '../../store/index'
export default [
  {
    path: '/workspace/',
    name: 'workspace',
    meta: {
      name: '工作区',
      authRequired: true
    },
    component: () => import('@/layouts/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'workspace.dashboard',
        meta: {
          name: '工作区首页',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/dashboard/index.vue')
      },
      {
        path: 'issues',
        name: 'workspace.issues',
        meta: {
          name: '意见建议',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/issues/index.vue')
      },
      {
        path: 'issues/:issue',
        name: 'workspace.issues.edit',
        meta: {
          name: '处理意见建议',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/issues/edit.vue')
      },
      {
        path: 'news',
        name: 'workspace.news',
        meta: {
          name: '社区新闻',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/news/index.vue')
      },
      {
        path: 'news/:news',
        name: 'workspace.news.edit',
        meta: {
          name: '社区新闻管理',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/news/edit.vue')
      },

      {
        path: 'cms/:collection',
        name: 'workspace.cms',
        meta: {
          name: to => {
            let collection = to.params?.collection ?? ''
            if (!collection) {
              return '内容列表'
            }
            return store.state.Common.Config.cmsCollections[collection]
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/cms/index.vue')
      },
      {
        path: 'cms/:collection/:id',
        name: 'workspace.cms.edit',
        meta: {
          name: to => {
            let collection = to.params?.collection ?? ''
            if (!collection) {
              return '内容管理'
            }
            return `${store.state.Common.Config.cmsCollections[collection]}管理`
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/cms/edit.vue')
      },
      {
        path: 'tree/',
        name: 'workspace.tree',
        meta: {
          name: () => {
            return '树木扫码绑定'
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/tree/index.vue')
      },

      {
        path: 'documents/:collection',
        name: 'workspace.documents',
        meta: {
          name: to => {
            let collection = to.params?.collection ?? ''
            if (!collection) {
              return '文档列表'
            }
            return store.state.Common.Config.documentCollections[collection]
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/documents/index.vue')
      },

      {
        path: 'documents/minutes/:id',
        name: 'workspace.documents.minutes',
        meta: {
          name: '会议纪要',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/documents/minutes/index.vue')
      },
      {
        path: 'documents/letters/:id',
        name: 'workspace.documents.letters',
        meta: {
          name: '公函管理',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/workspace/documents/letters/index.vue')
      }
    ]
  }
]
