import store from '../../store/index'
export default [
  {
    path: '/public/',
    name: 'public.home',
    meta: {
      name: '公开信息',
      authRequired: true
    },
    component: () => import('@/layouts/index.vue'),
    children: [
      {
        path: 'main',
        name: 'public.main',
        meta: {
          name: '服务大厅',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/main/index.vue')
      },
      {
        path: 'trees/genuses',
        name: 'public.trees.genuses',
        meta: {
          name: '树木种类',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/trees/genuses/index.vue')
      },
      {
        path: 'trees/genuses/:genus',
        name: 'public.trees.genuses.view',
        meta: {
          name: '树木信息',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/trees/genuses/view.vue')
      },
      {
        path: 'trees/map',
        name: 'public.trees.map',
        meta: {
          name: '树木地图',
          authRequired: true,
          verifiedOnly: false,
          home: false,
          back: false
        },
        component: () => import('@/views/public/trees/map/index.vue')
      },
      {
        path: 'trees/dashboard',
        name: 'public.trees.dashboard',
        meta: {
          name: '树木统计',
          authRequired: true,
          verifiedOnly: false,
          home: false,
          back: false
        },
        component: () => import('@/views/public/trees/dashboard/index.vue')
      },
      {
        path: 'pages/:page',
        name: 'public.pages',
        meta: {
          name: '内容',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/pages/index.vue')
      },
      {
        path: 'contact',
        name: 'public.contact',
        meta: {
          name: '联系方式',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/contact/index.vue')
      },
      {
        path: 'authentication',
        name: 'public.authentication',
        meta: {
          name: '业主认证',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/authentication/index.vue')
      },
      {
        path: 'issues',
        name: 'public.issues',
        meta: {
          name: '意见建议',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/issues/index.vue')
      },
      {
        path: 'issues/:issue/view',
        name: 'public.issues.get',
        meta: {
          name: '查看意见建议',
          authRequired: true,
          home: false
        },
        component: () => import('@/views/public/issues/view.vue')
      },
      {
        path: 'issues/edit',
        name: 'public.issues.edit',
        meta: {
          name: to => {
            return to.query?.id ? '编辑意见和建议' : '新增意见和建议'
          },
          authRequired: true,
          home: false
        },
        component: () => import('@/views/public/issues/edit.vue')
      },
      {
        path: 'news',
        name: 'public.news',
        meta: {
          name: '社区新闻',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/news/index.vue')
      },
      {
        path: 'news/:news/view',
        name: 'public.news.get',
        meta: {
          name: '查看社区新闻',
          authRequired: true,
          home: false
        },
        component: () => import('@/views/public/news/view.vue')
      },
      {
        path: 'cms/:collection/',
        name: 'public.cms',
        meta: {
          // eslint-disable-next-line no-console
          name: to => {
            let collection = to.params?.collection ?? ''
            if (!collection) {
              return '内容列表'
            }
            return store.state.Common.Config.cmsCollections[collection]
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/cms/index.vue')
      },

      {
        path: 'cms/:collection?/:id',
        name: 'public.cms.get',
        meta: {
          // eslint-disable-next-line no-console
          name: to => {
            let collection = to.params?.collection ?? ''
            if (!collection) {
              return '内容详情'
            }
            return `${store.state.Common.Config.cmsCollections[collection]}内容`
          },
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/cms/view.vue')
      },
      // 兼容老的通知公告地址
      {
        path: 'announcements/:id',
        name: 'public.announcements.get',
        meta: {
          name: '通知公告内容',
          authRequired: true,
          home: false,
          back: false
        },
        component: () => import('@/views/public/cms/view.vue')
      },
      {
        path: 'service/audit',
        name: 'public.service.audit',
        component: () => import('@/views/public/service/audit/index.vue'),
        children: [
          {
            path: 'home',
            name: 'public.service.audit.home',
            meta: {
              name: '物业考核-个人中心',
              authRequired: true,
              home: true,
              back: false
            },
            component: () =>
              import('@/views/public/service/audit/home/index.vue')
          },
          {
            path: 'home',
            name: 'public.service.audit.edit',
            meta: {
              name: '物业考核-我有话说',
              authRequired: true,
              home: true,
              back: false
            },
            component: () =>
              import('@/views/public/service/audit/edit/index.vue')
          },
          {
            path: 'manage',
            name: 'public.service.audit.manage',
            meta: {
              name: '物业考核-审核专区',
              authRequired: true,
              home: true,
              back: false
            },
            component: () =>
              import('@/views/public/service/audit/manage/index.vue')
          },
          {
            path: 'order',
            name: 'public.service.audit.order',
            meta: {
              name: '物业考核-考核大厅',
              authRequired: true,
              home: true,
              back: false
            },
            component: () =>
              import('@/views/public/service/audit/order/index.vue')
          }
        ]
      }
    ]
  }
]
