export default {
  namespaced: true,
  state: {
    publicMenuitems: [
      {
        id: 'public',
        name: '社区服务',
        children: [
          {
            id: 'public.announcements',
            route: {
              name: 'public.cms',
              params: {
                collection: 'announcements'
              }
            },
            title: '通知公告',
            icon: 'fa-megaphone',
            disabled: false
          },
          {
            id: 'public.news',
            route: {
              name: 'public.news'
            },
            title: '社区新闻',
            icon: 'fa-newspaper',
            disabled: false
          },
          {
            id: 'public.issues',
            route: {
              name: 'public.issues'
            },
            title: '意见建议',
            icon: 'fa-comment-alt-smile',
            disabled: false
          },
          {
            id: 'public.contact',
            route: {
              name: 'public.contact'
            },
            title: '联系我们',
            icon: 'fa-phone',
            disabled: false
          },
          {
            id: 'public.mp.guize',
            route: {
              name: 'public.mp.guize'
            },
            title: '议事规则',
            icon: 'fa-book-spells',
            link: 'https://mp.weixin.qq.com/s?__biz=Mzg5MzY4NjI3MA==&mid=2247483663&idx=1&sn=57ea50f7619a9befecda1207c42aa641&chksm=c02a599ef75dd088fc6237de7a2230f82f55f82d9342a403b37731bfeed08e24491dbc6963b7',
            disabled: false
          },
          {
            id: 'public.mp.guiyue',
            route: {
              name: 'public.mp.guiyue'
            },
            title: '管理规约（新）',
            icon: 'fa-book-user',
            link: 'https://mp.weixin.qq.com/s?__biz=Mzg5MzY4NjI3MA==&mid=2247484038&idx=1&sn=25cef3a47d053106116e24c9580ee3c8&chksm=c02a5a17f75dd3010eae0a01b6e9db1809310cce72cbbe8e8cffd86fab35dfcb22c5250b9eda#rd',
            disabled: false
          },
          {
            id: 'public.mp.guifan',
            route: {
              name: 'public.pages',
              params: { page: 'Residential-Property-Service-Standard' }
            },
            title: '物业服务规范',
            className: 'text-xs text-center',
            icon: 'fa-toolbox',
            disabled: false
          },
          {
            id: 'public.service.audit',
            route: {
              name: 'public.service.audit.home'
            },
            title: '物业考核',
            icon: 'fa-concierge-bell',
            disabled: true
          }
        ]
      },
      {
        id: 'trees',
        name: '绿色资产管理',
        children: [
          {
            id: 'public.trees.genuses',
            route: {
              name: 'public.trees.genuses'
            },
            title: '树木种类',
            icon: 'fa-trees',
            disabled: false
          },
          {
            id: 'public.trees.map',
            route: {
              name: 'public.trees.map'
            },
            title: '树木地图',
            icon: 'fa-map-marked',
            disabled: false,
            verifiedOnly: false
          },
          {
            id: 'public.trees.dashboard',
            route: {
              name: 'public.trees.dashboard'
            },
            title: '树木统计',
            icon: 'fa-chart-pie',
            disabled: false,
            verifiedOnly: false
          },
          {
            id: 'public.trees.volunteer',
            route: {
              name: 'public.trees.volunteer'
            },
            title: '小小护林员',
            icon: 'fa-acorn',
            miniapp: {
              username: 'gh_8b37c759381b',
              path: '/pages/index/index.html'
            },
            disabled: false,
            verifiedOnly: false
          }
        ]
      },
      {
        id: 'private',
        name: '业主专区',
        children: [
          {
            id: 'public.authentication',
            route: {
              name: 'public.authentication'
            },
            title: '业主认证',
            icon: 'fa-users',
            disabled: false
          },
          {
            id: 'public.security',
            route: {
              name: 'public.cms',
              params: {
                collection: 'security'
              }
            },
            title: '门禁专题',
            icon: 'fa-door-open',
            disabled: false
          },
          {
            id: 'public.fire',
            route: {
              name: 'public.cms',
              params: {
                collection: 'fire'
              }
            },
            title: '消防专题',
            icon: 'fa-fire-extinguisher',
            disabled: false
          },
          {
            id: 'public.elevator',
            route: {
              name: 'public.cms',
              params: {
                collection: 'elevator'
              }
            },
            title: '电梯专题',
            icon: 'fal fa-person-booth',
            disabled: false
          }
        ]
      }
    ],
    workspaceMenuitems: [
      {
        id: 'cms',
        name: '内容管理',
        tags: ['业委会'],
        children: [
          {
            id: 'cms.announcements',
            route: {
              name: 'workspace.cms',
              params: {
                collection: 'announcements'
              }
            },
            title: '通知公告管理',
            icon: 'fa-megaphone',
            disabled: false
          },
          {
            id: 'cms.news',
            route: {
              name: 'workspace.news'
            },
            title: '社区新闻管理',
            icon: 'fa-newspaper',
            disabled: false
          },
          {
            id: 'cms.security',
            route: {
              name: 'workspace.cms',
              params: {
                collection: 'security'
              }
            },
            title: '门禁专题',
            icon: 'fa-door-open',
            disabled: false
          },
          {
            id: 'cms.fire',
            route: {
              name: 'workspace.cms',
              params: {
                collection: 'fire'
              }
            },
            title: '消防专题',
            icon: 'fa-fire-extinguisher',
            disabled: false
          }
        ]
      },
      {
        id: 'documents',
        name: '公文管理',
        tags: ['业委会', '监事会'],
        children: [
          {
            id: 'documents.minutes',
            route: {
              name: 'workspace.documents',
              params: {
                collection: 'minutes'
              }
            },
            title: '会议纪要',
            icon: 'fa-users',
            disabled: false
          },
          {
            id: 'documents.letters',
            route: {
              name: 'workspace.documents',
              params: {
                collection: 'letters'
              }
            },
            title: '公函管理',
            icon: 'fa-envelope',
            disabled: false
          }
        ]
      },
      {
        id: 'service',
        name: '社区服务',
        tags: ['业委会'],
        children: [
          {
            id: 'service.issues',
            route: {
              name: 'workspace.issues'
            },
            title: '意见建议管理',
            icon: 'fa-comment-alt-smile',
            disabled: false
          },
          {
            id: 'service.tree',
            route: {
              name: 'workspace.tree'
            },
            title: '树木绑定',
            icon: 'fa-trees',
            disabled: false
          }
        ]
      }
    ]
  }
}
