import { Notify } from 'vant'
export default {
  namespaced: true,
  state: {
    userInfo: null,
    checkLoginResult: null,
    loginState: false,
    openId: null,
    info: null
  },
  mutations: {
    setUserInfo(state, subscriber) {
      state.userInfo = subscriber
      state.openId = subscriber.openid ?? subscriber.openId
    },
    setCheckLoginResult(state, checkLoginResult) {
      state.checkLoginResult = checkLoginResult
    },
    setLoginState(state) {
      state.loginState = true
    },
    setExecutionInfo(state, info) {
      state.info = info
    },
    reset(state) {
      state.userInfo = null
      state.checkLoginResult = null
      state.loginState = false
      state.openId = null
      state.info = null
    }
  },
  actions: {
    async initialize({ commit }) {
      try {
        let urlSearch = new URLSearchParams(location.search)
        let accessToken = urlSearch.get('access_token')
        let refreshToken = urlSearch.get('refresh_token')
        // eslint-disable-next-line no-alert
        // alert(JSON.stringify(urlSearch))
        const checkLoginOptions = {
          provider: 'OfficialAccount',
          appid: process.env.VUE_APP_OFFICIAL_ACCOUNT_APPID
        }

        if (urlSearch.get('oauthredirect') === '1') {
          checkLoginOptions.accessToken = accessToken
          checkLoginOptions.refreshToken = refreshToken
        }
        // eslint-disable-next-line no-console
        console.log('checkLogin checkLoginOptions', checkLoginOptions)
        const result = await wx.cloud.checkLogin(checkLoginOptions)
        commit('setExecutionInfo', '检查公众号的登录状态')
        commit('setCheckLoginResult', result)
        // eslint-disable-next-line no-console
        console.log('checkLogin setCheckLoginResult', result)

        if (result.errCode === 0 && result.loggedIn) {
          commit('setExecutionInfo', '当前用户已经完成微信授权')
          const settings = {
            appid: process.env.VUE_APP_OFFICIAL_ACCOUNT_APPID,
            resourceAppid: process.env.VUE_APP_MINIAPP_APPID,
            resourceEnv: process.env.VUE_APP_MINIAPP_ENVID
          }
          // eslint-disable-next-line no-console
          console.log('checkLogin success', settings)

          const instance = new wx.cloud.Cloud(settings)
          const initResult = await instance.init()

          // eslint-disable-next-line no-console
          console.log('instance inited', initResult)
          commit('setLoginState')
          commit('Common/Cloud/setTcb', instance, {
            root: true
          })
          commit('setExecutionInfo', '开始获取用户信息')
          let { cloudID } = result
          // eslint-disable-next-line no-console
          console.log('当前的用户信息', cloudID)
          try {
            const res = await instance.callFunction({
              name: 'echo',
              data: {
                userInfoData: new instance.CloudID(cloudID),
                version: 1
              }
            })

            // eslint-disable-next-line no-console
            console.log('成功获取用户信息', res)
            let subscriber = res.result
            commit('setExecutionInfo', `${subscriber.nickName} 欢迎回来！`)
            commit('setUserInfo', subscriber)
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('call echo failed', err)
          }
        } else {
          commit('setExecutionInfo', '启动微信授权程序')
          let host = window.location.host
          const redirectUri = `https://${host}/#/wechat/oauth`

          // eslint-disable-next-line no-console
          console.log(
            '跳转后的URL',
            redirectUri,
            sessionStorage.getItem('redirect')
          )
          commit('setExecutionInfo', '准备跳转到授权页面')
          // eslint-disable-next-line no-alert
          // alert('跳转地址' + redirectUri)
          setTimeout(() => {
            try {
              wx.cloud.startLogin({
                provider: 'OfficialAccount',
                appid: process.env.VUE_APP_OFFICIAL_ACCOUNT_APPID,
                scope: 'snsapi_userinfo',
                redirectUri
              })
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(`startLogin fail: ${e}`)
              commit('setExecutionInfo', '无法启动微信授权程序')
            }
          }, 3e3)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('登录状态错误', err)
      }
    },
    async fetch({ commit, rootState, state }) {
      if (state.committees) {
        return state.committees
      }
      try {
        const tcb = rootState.Common.Cloud.tcb
        const response = await tcb.callFunction({
          name: 'subscribers',
          data: {
            $url: 'get'
          }
        })
        if (response.result?.error) {
          Notify({ type: 'danger', message: response.result.error })
        } else {
          commit('setUserInfo', response.result.data)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        Notify({ type: 'danger', message: '无法获取个人信息' })
      }
    },
    reset({ commit }) {
      commit('reset')
      sessionStorage.clear()
    }
  },
  getters: {
    userInfo: state => state.userInfo
  }
}
