import { Notify } from 'vant'
export default {
  namespaced: true,
  state: {
    committees: null
  },
  mutations: {
    setCommittees(state, committees) {
      state.committees = committees
    },
    reset(state) {
      state.committees = null
    }
  },
  actions: {
    async fetch({ commit, rootState, state }) {
      if (state.committees) {
        return state.committees
      }
      try {
        const tcb = rootState.Common.Cloud.tcb
        const response = await tcb.callFunction({
          name: 'wechat',
          data: {
            $url: 'getCommittees'
          }
        })
        if (response.result?.error) {
          Notify({ type: 'danger', message: response.result.error })
        } else {
          commit('setCommittees', response.result.data)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        Notify({ type: 'danger', message: '无法获取业委会委员名单' })
      }
    },
    reset({ commit }) {
      commit('reset')
    }
  }
}
