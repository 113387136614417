import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
dayjs.locale('zh-cn')
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default {
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      $dayjs: {
        get: () => {
          return dayjs
        },
      }
    })
  }
}