export default {
  namespaced: true,
  state: {
    units: [
      {
        text: '1号楼',
        children: [
          {
            text: '1单元'
          },
          {
            text: '2单元'
          },
          {
            text: '3单元'
          },
          {
            text: '4单元'
          },
          {
            text: '5单元'
          },
          {
            text: '6单元'
          },
          {
            text: '7单元'
          }
        ]
      },
      {
        text: '2号楼',
        children: [
          {
            text: '1单元'
          },
          {
            text: '2单元'
          },
          {
            text: '3单元'
          },
          {
            text: '4单元'
          },
          {
            text: '5单元'
          },
          {
            text: '6单元'
          },
          {
            text: '7单元'
          },
          {
            text: '8单元'
          },
          {
            text: '9单元'
          },
          {
            text: '10单元'
          }
        ]
      },
      {
        text: '3号楼',
        children: [
          {
            text: '1单元'
          },
          {
            text: '2单元'
          },
          {
            text: '3单元'
          },
          {
            text: '4单元'
          },
          {
            text: '5单元'
          },
          {
            text: '6单元'
          }
        ]
      },
      {
        text: '4号楼',
        children: [
          {
            text: '1单元'
          },
          {
            text: '2单元'
          },
          {
            text: '3单元'
          },
          {
            text: '4单元'
          },
          {
            text: '5单元'
          },
          {
            text: '6单元'
          }
        ]
      },
      {
        text: '5号楼',
        children: [
          {
            text: '1单元'
          },
          {
            text: '2单元'
          },
          {
            text: '3单元'
          },
          {
            text: '4单元'
          },
          {
            text: '5单元'
          }
        ]
      },
      {
        text: '6号楼',
        children: [
          {
            text: '1单元'
          }
        ]
      },
      {
        text: '7号楼',
        children: [
          {
            text: '1单元'
          }
        ]
      },
      {
        text: '北区',
        children: [
          {
            text: '底商'
          },
          {
            text: '车库'
          },
          {
            text: '其他'
          }
        ]
      },
      {
        text: '南区',
        children: [
          {
            text: '底商'
          },
          {
            text: '车库'
          },
          {
            text: '其他'
          }
        ]
      },
      {
        text: '其他',
        children: [
          {
            text: '其他'
          }
        ]
      }
    ],
    issueCategories: [
      '物业管理',
      '消防安全',
      '电梯安全',
      '安保相关',
      '保洁相关',
      '设施维护',
      '非机动车停放',
      '机动车停放',
      '其他'
    ],
    issuesStatus: {
      待处理: 'primary',
      处理中: 'warning',
      已完成: 'success'
    },
    issuesStatusName: {
      待处理: '待处理',
      处理中: '处理中',
      已完成: '已办结',
      已删除: '已删除'
    },
    issuesSources: ['公众号', '电话', '电子邮件', '办公室', '来访', '其他'],
    newsStatus: {
      草稿: 'warning',
      已发布: 'success'
    },
    announcementStatus: {
      草稿: 'warning',
      已发布: 'success'
    },
    articleStatus: {
      草稿: 'warning',
      已发布: 'success'
    },
    cmsCollections: {
      announcements: '通知公告',
      security: '门禁专题',
      fire: '消防专题',
      elevator: '电梯专题'
    },
    documentCollections: {
      letters: '公函',
      minutes: '会议纪要'
    },
    poiCategories: {
      核酸检测点: {
        icon: { url: '/map/poi/dabai.png', size: { width: 64, height: 64 } },
        labelStyle: {
          color: 'red',
          fontSize: '12px',
          backgroundColor: '#fce4ec',
          transform: 'translate(calc(32px - 50%), 68px)'
        }
      },
      家: {
        icon: { url: '/map/poi/home.png', size: { width: 32, height: 32 } },
        labelStyle: {
          border: 'none',
          backgroundColor: '#fffde7',
          color: '#000',
          fontSize: '12px',
          transform: 'translate(calc(16px - 50%), 36px)'
        }
      },
      派出所: {
        icon: { url: '/map/poi/gonganju.png', size: { width: 32, height: 32 } },
        labelStyle: {
          border: 'none',
          backgroundColor: '#e3f2fd',
          color: '#1565c0',
          fontSize: '12px',
          transform: 'translate(calc(16px - 50%), 36px)'
        }
      }
    }
  }
}
